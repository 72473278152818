<template>
  <div class="CompletedRounds">
    <!-- 未连接钱包 begin -->
    <div
      class="no-data"
      v-if="connectedStatus==0"
    >
      <div class="no-data-desc">{{$t('menu.connectYourWallet')}}</div>
      <div class="no-data-desc">{{$t('menu.starsThisRound')}}</div>
      <button
        class="no-data-btn"
        @click="openDialog(connectedStatus,1)"
      >{{$t('menu.connectWallet')}}</button>
    </div>
    <!-- 未连接钱包 end -->
    <!-- 没有数据 begin -->
    <div
      class="no-data"
      v-if="connectedStatus==1&&isRoundsData==false"
    >
      <div class="no-data-desc">{{$t('menu.noExchanged')}}</div>
      <router-link :to="{name:'ExchangeLuckyStars',path:'/exchangeLuckyStars'}" class="no-data-btn">{{$t('menu.toExchange')}}</router-link>
    </div>
    <!-- 没有数据 end -->
    <!-- 有申购数据 begin -->
    <div v-if="connectedStatus==1&&isRoundsData">
      <div class="box-draw-prize">
        <div class="prize-head">
          <div class="head-one com-all-head">
            <div class="left"><i>{{$t('menu.round')}}</i><span>{{lotteryId}}</span></div>
            <div class="prev-img-p">
              <span @click="getPgage(0)"><img
                  src="../../../../../../public/images/lukyStar/icon-left.png"
                  alt=""
                ></span>
              <span @click="getPgage(1)"><img
                  src="../../../../../../public/images/lukyStar/icon-right.png"
                  alt=""
                ></span>
            </div>
          </div>
          <div class="head-two">
            <!-- {{$t('menu.prizeBeenDrawn')}}  -->
            <div class="left color-grey">
              {{$t('menu.'+statusText[viewLottery.status])}} {{formatTimeToType1(viewLottery.endTime)}}
            </div>
            <div class="prev-num-p">
              <i>{{currentPage}}</i><span>/{{totalPage}}</span>
            </div>
          </div>
        </div>
        <div class="prize-box">
          <div class="title">{{$t('menu.winningNumbers')}}<span class="new-flag"><img src="../../../../../../public/images/lukyStar/new.png" alt=""></span></div>
          <div class="num all-com-winning openPrizeNumber">
            <span v-for="(item,index) in finalNumberList" :key="index">
              <img v-if="item=='0'" src="../../../../../../public/images/lukyStar/num0.png" alt="">
              <img v-if="item=='1'" src="../../../../../../public/images/lukyStar/num1.png" alt="">
              <img v-if="item=='2'" src="../../../../../../public/images/lukyStar/num2.png" alt="">
              <img v-if="item=='3'" src="../../../../../../public/images/lukyStar/num3.png" alt="">
              <img v-if="item=='4'" src="../../../../../../public/images/lukyStar/num4.png" alt="">
              <img v-if="item=='5'" src="../../../../../../public/images/lukyStar/num5.png" alt="">
              <img v-if="item=='6'" src="../../../../../../public/images/lukyStar/num6.png" alt="">
              <img v-if="item=='7'" src="../../../../../../public/images/lukyStar/num7.png" alt="">
              <img v-if="item=='8'" src="../../../../../../public/images/lukyStar/num8.png" alt="">
              <img v-if="item=='9'" src="../../../../../../public/images/lukyStar/num9.png" alt="">
            </span>
          </div>
        </div>
      </div>
      <div class="prize-container">
        <div class="p-name">
          <div>{{$t('menu.prizePool')}}</div>
          <div class="type-choose" @click="chooseCurrency">
            <span v-if="currentCurrency==0">STAR</span>
            <span v-if="currentCurrency==1">ZEED</span>
            <span v-if="currentCurrency==2">YEED</span>
            <img src="../../../../../../public/images/lukyStar/icon-down.png" alt="">
          </div>
        </div>
        <div class="p-num">${{conversionToUsdt(viewLottery.amountCollectedInCake)}}</div>
        <div class="p-tip"><i>{{viewLottery.amountCollectedInCake}}</i><span>{{currentCurrencyText}}</span></div>
        <div class="p-total">{{$t('menu.roundMemberTotal')}}<span>{{viewLottery.firstTicketIdNextLottery - viewLottery.firstTicketId}}</span></div>
        <div class="p-desc">{{$t('menu.prizeDescTip')}}</div>
        <div class="desc-content">
          <div class="list">
            <div class="title">{{$t('menu.hitTheTop1')}}</div>
            <div class="num"><span>{{viewLottery.rewardsBreakdownAmount[0]}}</span> {{currentCurrencyText}}</div>
            <div class="tip">${{conversionToUsdt(viewLottery.rewardsBreakdownAmount[0])}}</div>
            <div class="tip"> {{amountConversion(viewLottery.cakePerBracket[0])}} {{currentCurrencyText}} {{$t('menu.each')}}</div>
            <div class="tip"> {{viewLottery.countWinnersPerBracket[0]}} {{$t('menu.winningLuckyStar')}}</div>
          </div>
          <div class="list">
            <div class="title">{{$t('menu.hitTheTop2')}}</div>
            <div class="num"><span>{{viewLottery.rewardsBreakdownAmount[1]}}</span> {{currentCurrencyText}}</div>
            <div class="tip">${{conversionToUsdt(viewLottery.rewardsBreakdownAmount[1])}}</div>
            <div class="tip"> {{amountConversion(viewLottery.cakePerBracket[1])}} {{currentCurrencyText}} {{$t('menu.each')}}</div>
            <div class="tip"> {{viewLottery.countWinnersPerBracket[1]}} {{$t('menu.winningLuckyStar')}}</div>
          </div>
          <div class="list">
            <div class="title">{{$t('menu.hitTheTop3')}}</div>
            <div class="num"><span>{{viewLottery.rewardsBreakdownAmount[2]}}</span> {{currentCurrencyText}}</div>
            <div class="tip">${{conversionToUsdt(viewLottery.rewardsBreakdownAmount[2])}}</div>
            <div class="tip"> {{amountConversion(viewLottery.cakePerBracket[2])}} {{currentCurrencyText}} {{$t('menu.each')}}</div>
            <div class="tip"> {{viewLottery.countWinnersPerBracket[2]}} {{$t('menu.winningLuckyStar')}}</div>
          </div>
          <div class="list">
            <div class="title">{{$t('menu.hitTheTop4')}}</div>
            <div class="num"><span>{{viewLottery.rewardsBreakdownAmount[3]}}</span> {{currentCurrencyText}}</div>
            <div class="tip">${{conversionToUsdt(viewLottery.rewardsBreakdownAmount[3])}}</div>
            <div class="tip"> {{amountConversion(viewLottery.cakePerBracket[3])}} {{currentCurrencyText}} {{$t('menu.each')}}</div>
            <div class="tip"> {{viewLottery.countWinnersPerBracket[3]}} {{$t('menu.winningLuckyStar')}}</div>
          </div>
          <div class="list">
            <div class="title">{{$t('menu.hitTheTop5')}}</div>
            <div class="num"><span>{{viewLottery.rewardsBreakdownAmount[4]}}</span> {{currentCurrencyText}}</div>
            <div class="tip">${{conversionToUsdt(viewLottery.rewardsBreakdownAmount[4])}}</div>
            <div class="tip"> {{amountConversion(viewLottery.cakePerBracket[4])}} {{currentCurrencyText}} {{$t('menu.each')}}</div>
            <div class="tip"> {{viewLottery.countWinnersPerBracket[4]}} {{$t('menu.winningLuckyStar')}}</div>
          </div>
          <div class="list">
            <div class="title">{{$t('menu.hitTheTop6')}}</div>
            <div class="num"><span>{{viewLottery.rewardsBreakdownAmount[5]}}</span> {{currentCurrencyText}}</div>
            <div class="tip">${{conversionToUsdt(viewLottery.rewardsBreakdownAmount[5])}}</div>
            <div class="tip"> {{amountConversion(viewLottery.cakePerBracket[5])}} {{currentCurrencyText}} {{$t('menu.each')}}</div>
            <div class="tip"> {{viewLottery.countWinnersPerBracket[5]}} {{$t('menu.winningLuckyStar')}}</div>
          </div>
          <div class="list">
            <div class="title red">{{$t('menu.incinerate')}}</div>
            <div class="num"><span>{{viewLottery.treasuryAmount}}</span> {{currentCurrencyText}}</div>
            <div class="tip">${{conversionToUsdt(viewLottery.treasuryAmount)}}</div>
          </div>
        </div>

      </div>
    </div>
    <!-- 有申购数据 end -->
    <Star-Wallet
      v-if="dialogShow"
      :id="0"
      @transfer="openDialog"
    ></Star-Wallet>
    <Exchange-Dialog v-if="isShowDialog" :isType="isTypeChoosed" :currencyType="currentCurrency" @transfer="handleTypeData"></Exchange-Dialog>
  </div>
</template>

<script>
// import '../../styles/CompletedRounds.css';
import '../../styles/common.css';
import StarWallet from '../../common/StarWallet.vue';
import ExchangeDialog from '../../Dialog/ExchangeDialog.vue'
const mLottery = require('../../../../../utils/mLottery')

export default {
  components: { StarWallet,ExchangeDialog },
  data() {
    return {
      connectedStatus: 0,//未连接=0;连接成功=1
      dialogShow: false,
      isRoundsData: true,//是否有申购数据
      prizeNum: "732", //第n次开奖
      currentPage: 0,//当前页
      totalPage: 0, //总页数
      winningNumbersList:['2','3','4','9','7','5'], //中奖号码
      currentCurrency:0, //0是STAR 1是ZEED 2是YEED
      currentCurrencyText:'',
      isTypeChoosed:'0', //0选择币种  1点击申购
      isShowDialog:false, //是否打开选择币种弹窗
      lotteryId:0,
      currencyList:['STAR','ZEED','YEED'],
      viewLottery:{
        status: "0", // Pending没开始 1，Open开始 2，Close停止投注 3，Claimable可以领奖 4
        startTime: "0", //开始时间
        endTime: "0", // 结束投注时间
        priceTicketInCake: "0", // 一张彩票的价格，对应star、zeed、yeed
        discountDivisor: "0",// 这个变量不用理
        rewardsBreakdown: [], //每个挡位的奖励比例， x/10000
        treasuryFee: "0", // 打入黑洞的比例，x/10000
        cakePerBracket: [], // 开奖后，每个挡位中奖币数量
        countWinnersPerBracket: [], // 开奖后，每个挡位中奖的用户数
        firstTicketId: "0",
        firstTicketIdNextLottery: "0",
        amountCollectedInCakePre: "0", // 上轮剩下来的币
        amountCollectedInCake: "0", // 本轮奖池的币
        finalNumber: "0", // 开奖号码
        rewardsBreakdownAmount:[],
        treasuryAmount:0,
      },
      priceList:[0,0,0],
      finalNumberList:[],//开奖号码
      formatTimeToType1:mLottery.default.formatTimeToType1,
      amountConversion:mLottery.default.amountConversion,
      statusText:['notStarted','start','stopBetting','acceptAward']
    }
  },
  created: function () {
    var address = localStorage.getItem('address')
    if (address) {
      this.connectedStatus = 1;
    }

    this.currentCurrencyText = this.currencyList[this.currentCurrency]

    // 
    mLottery.default.switchContract(this.currentCurrency)
    mLottery.default.viewCurrentLotteryId().then(currentLotteryId=>{
        console.log('currentLotteryId',currentLotteryId)
        // 获取最大回合
        mLottery.default.viewLottery(currentLotteryId).then(viewLotteryRes => {
          let lotteryId = currentLotteryId
          if (viewLotteryRes.status < 3) {
            lotteryId = currentLotteryId - 1
          }

          this.lotteryId = lotteryId
          this.currentPage = lotteryId
          this.totalPage = lotteryId

          this.getViewData() //获取页面数据
        })
        
    })

    // 获取Star价格
    mLottery.default.getStarAddrPrice().then(starPrice=>{
      this.priceList[0] = starPrice
    })
    // 获取Zeed价格
    mLottery.default.getZeedAddrrPrice().then(zeedPrice=>{
      this.priceList[1] = zeedPrice
    })
    // 获取Yeed价格
    mLottery.default.getYeedAddrPrice().then(yeedPrice=>{
      this.priceList[2] = yeedPrice
    })
  },
  methods: {
    getPgage(type) {//下一页，上一页功能
      console.log(type);
      
      if (type == 0 && this.currentPage>1) {
        this.currentPage = this.currentPage - 1;
        this.getViewData() //获取页面数据
      }
      if (type == 1 && this.currentPage<this.totalPage) {
        this.currentPage = this.currentPage + 1;
        this.getViewData() //获取页面数据
      }
      

    },
    openDialog(connectStatus, type) {
      if (type == 1) {//type=1 打开钱包弹窗，type=2 关闭钱包弹窗
        this.dialogShow = true;
        console.log(connectStatus, '==', type);
      }
      if (type == 2) {
        this.dialogShow = false;
        this.connectedStatus = connectStatus;
      }
    },
    chooseCurrency(){
      this.isShowDialog = true;
      this.isTypeChoosed = '0';
    },
    handleTypeData(type,isClose,currType){
      if(type=='0'){//此判断里进行操作-选择币种底部那个弹窗
        this.currentCurrency = currType;//选择后赋值
        this.currentCurrencyText = this.currencyList[this.currentCurrency]
        mLottery.default.switchContract(this.currentCurrency)
        this.getViewData() //获取页面数据
      }
      if(isClose==0){//此判断里进行关闭弹窗的操作
        this.isShowDialog = false;
      }else{
        this.isShowDialog = true;
      }
    },
    // 获取页面数据
    getViewData(){
      let that =this
      let lotteryId =that.currentPage
      that.lotteryId = lotteryId
      mLottery.default.viewLottery(lotteryId).then(viewLotteryRes=>{
          let viewLottery = Object.assign({}, viewLotteryRes)
          viewLottery.amountCollectedInCake = mLottery.default.amountConversion(viewLottery.amountCollectedInCake)
          let rewardsBreakdownAmount = []
          for (let index = 0; index < viewLottery.rewardsBreakdown.length; index++) {
            rewardsBreakdownAmount[index] = Math.floor((viewLottery.amountCollectedInCake * viewLottery.rewardsBreakdown[index] /10000) * 1000000) / 1000000; 
          }
          viewLottery.rewardsBreakdownAmount = rewardsBreakdownAmount
          viewLottery.treasuryAmount =  Math.floor((viewLottery.amountCollectedInCake * viewLottery.treasuryFee /10000) * 1000000) / 1000000; 

          that.viewLottery = Object.assign({},that.viewLottery, viewLottery)
          console.log(that.viewLottery)

          // 开奖号码 处理
          that.finalNumberList = mLottery.default.formatTicketNumberForResponse(viewLottery.finalNumber) //格式化彩票号码
          console.log('finalNumber',viewLotteryRes.finalNumber,that.finalNumberList)
          

        })
    },
    // 转换位usdt 
    conversionToUsdt(_amount){
      return Math.floor((_amount * this.priceList[this.currentCurrency]) * 1000000) / 1000000; 
    }
  }
}
</script>

<style>
</style>