<template>
  <div class="CompletedRounds">
      <!-- 历史记录/所有历史记录(tab切换) begin  -->
      <div class="rounds-tab-title">
        <div class="rounds-tit" :class="[isCompleted==index?'isSelectdRounds':'']" v-for="(item,index) in roundsTabList" :key="index" @click="chooseRound(index)">{{$t("menu."+item)}}</div>
      </div>
      <!-- 历史记录/所有历史记录(tab切换) end  -->
      <Completed-History v-if="isCompleted==0"></Completed-History>
      <Completed-All v-if="isCompleted==1"></Completed-All>
  </div>
</template>

<script>
import '../../styles/CompletedRounds.css';
import '../../styles/common.css';
import CompletedHistory from './CompletedHistory.vue';
import CompletedAll from './CompletedAll.vue';

export default {
  components:{CompletedHistory,CompletedAll},
    data() {
      return {
        isCompleted:0,//0默认 历史记录，1是 所有历史记录
        roundsTabList:["history","allHistory"]
      }
    },
    created:function(){
      
    },
    methods:{
      chooseRound(index){
        this.isCompleted = index;
      },

      // Step2 领取奖励
      claimTickets(){
        
      }
    }
}
</script>

<style>

</style>